import { debounce, set } from "lodash";
import React, { useEffect } from "react";
import { Button, Datagrid, DateField, FunctionField, List, ListBase, ListToolbar, NumberField, Pagination, Resource, SelectInput, TextField, useGetIdentity, useGetList, useGetOne, useListContext, useStore } from 'react-admin';
import {
    Box, TextField as MuiTextField,
    Button as MuiButton, Grid
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useState } from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterModal from '../manageSubscribers/FilterModal';
import { useForm, FormProvider } from "react-hook-form";
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import SelectField from "../common/InputComponents/SelectField";
import moment from "moment";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputDateField from "../common/InputComponents/InputDateFieldOld";

const FilterReports = () => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
        useListContext();
        const today = new Date();
            today.setHours(0, 0, 0, 0); // Normalize to midnight in local time

            // Calculate 30 days before today
            const thirtyDaysAgo = new Date(today);
            thirtyDaysAgo.setDate(today.getDate() - 30);

        const [rangefrom, setRangefrom] = useState<Date>(thirtyDaysAgo); // Start date (initially null)
        const [rangeto, setRangeto] = useState<Date>(new Date()); // End date (initially null)

  
      
        const formatDate = (date: Date) => {
            if (isNaN(date.getTime())) {
                return " ";
            }
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
            const day = date.getDate().toString().padStart(2, '0');
            const year = date.getFullYear();
    
            return `${month}/${day}/${year}`;
        };
        
        const validationSchema = Yup.object().shape({
            rangeto: Yup.string()
                .required("Required!")
                .nullable()
                .test("is-valid-date", "Invalid date format", (value) => {
                    return value ? moment(value, "MM/DD/YYYY", true).isValid() : true;
                }),
                rangefrom: Yup.string()
                .required("Required!")
                .nullable()
                .test("is-valid-date", "Invalid date format", (value) => {
                    return value ? moment(value, "MM/DD/YYYY", true).isValid() : true;
                }),
        });
    const form = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...filterValues
        },
    });

    // useeffect to set rangefrom and rangeto to the current date if filterValues is empty
    useEffect(() => {
        if(!filterValues?.rangefrom && !filterValues?.rangeto){
            form.setValue("rangefrom", formatDate(rangefrom));
            form.setValue("rangeto", formatDate(rangeto));
        }else{
            form.setValue("rangefrom", filterValues?.rangefrom);
            form.setValue("rangeto", filterValues?.rangeto);
        }
    }, [filterValues]);

    const applyFilter = (values) => {
        if (Object.keys(values).length > 0) {
          const filter = {
            ...values,
            end_date: values.end_date
              ? moment(values.end_date).format("MM/DD/yyyy")
              : "",
            start_date: values.start_date
              ? moment(values.start_date).format("MM/DD/yyyy")
              : "",
          };
          setFilters(filter, displayedFilters);
          handleClose();
        }
      };
      const onSubmit = (values: any) => {
    
          
        debugger;
          applyFilter({
            ...filterValues,
            ...values,
            app_invitation_type: filterValues?.app_invitation_type || 1,
          });
        //   form.reset({...values});
        }
    
    const start_date = form.watch("rangefrom");
    return (
        <div>
            <div className='row' style={{  top: 10, zIndex: '99999' }}>

                <div className='col-12 col-md-12 col-sm-12' style={{ zIndex: 1000,position:"relative" }}>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            <Box
                                className="date-label"
                                sx={{
                                    position: "relative",
                                    paddingTop: 1,
                                    display: "flex", // Add this to make the fields display inline
                                    alignItems: "center",
                                    justifyContent: "space-between", // Add this to make the fields take equal space

                                }}
                            >
                                <span style={{ paddingRight: 2, paddingBottom: 10 }}>
                                    <strong>From:</strong>
                                </span>
                                <InputDateField
                                    source={"rangefrom"}
                                    maxDate={new Date()}
                                    required={false}
                                />
                                <strong
                                    style={{
                                        paddingRight: 2,
                                        paddingLeft: 2,
                                        paddingBottom: 10,
                                        fontSize: "14px",
                                        color: "#303030",
                                        fontWeight: "inherit",
                                    }}
                                >
                                    &nbsp;To:&nbsp;
                                </strong>
                                <InputDateField
                                    source={"rangeto"}
                                    maxDate={new Date()}
                                    minDate={start_date  ? moment(start_date ).toDate() : undefined}
                                    required={false}
                                />
                                <MuiButton sx={{ mx: 1, marginBottom: 2 }} variant="contained" type="submit">
                                    Apply
                                </MuiButton>
                                
                            </Box>

                        </form>
                    </FormProvider></div>
            </div>
        </div>
    )
}

export default FilterReports;


// const MyListToolBar = (props) => {
//     const { Office, register, fieldValue } = props;
//     const [open, setOpen] = React.useState(false);
//     const [switchSubscriberObj] = useStore("switchSubscriberName");
//     const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
//         useListContext();
//     console.log({ filterValues, setFilters });

//     // Debounce the search function
//     const debouncedSearch = debounce((value) => {
//         setFilters({
//             ...filterValues,
//             q: value,  // Update the search_by filter with the new search value
//         }, []);
//     }, 500);  // 500ms debounce delay

//     const handleSearchChange = (event) => {
//         const value = event.target.value;
//         debouncedSearch(value);
//     };
//     const form = useForm({
//         defaultValues: filterValues,
//     });
//     const onSubmit = (value) => {
//         console.log(value);
//         console.log("displayedFilters", displayedFilters)
//         console.log("filterValues", filterValues)
//         setFilters({ ...value }, displayedFilters);
//         setOpen(false);
//     };

//     const handleExportDocUploadManagementReport = () => {
//         const tokenString = localStorage.getItem('auth');
//         let tokenSecret = '';

//         if (tokenString) {
//             try {
//                 const token = JSON.parse(tokenString);
//                 tokenSecret = token?.data?.tokendata || '';
//             } catch (error) {
//                 console.error('Error parsing token:', error);
//             }
//         }

//         const currentTimestamp = Date.now();

//         // Updated URL for exporting Document Upload Management Report
//         const url = `${ATS_API_URL}/subscriber/exportdocuploadmanagementreport?order_by=&sort_by=&search_by=null&up_limit=10000&low_limit=0&range_from=null&range_to=null&subscriberid=${switchSubscriberObj?.value || 2}`;


//         fetch(url, {
//             method: 'GET',
//             headers: {
//                 'Authorization': `${tokenSecret}`
//             }
//         }).then(response => response.blob())
//             .then(blob => {
//                 const url = window.URL.createObjectURL(blob);
//                 const a = document.createElement('a');
//                 a.style.display = 'none';
//                 a.href = url;
//                 a.download = `docUploadManagementReport-${currentTimestamp}.xlsx`;
//                 document.body.appendChild(a);
//                 a.click();
//                 window.URL.revokeObjectURL(url);
//             })
//             .catch(error => console.error('Error:', error));
//     };

   
   
//     return <>
//         <div >


//         </div>
//         <br />
//     </>
//     return (


//         <div style={{ marginTop: 30, marginBottom: 100, position: "absolute", zIndex: 999999, top: 0 }}>
//             <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
//                 {/* Left side with title */}
//                 {/* margin-top: 30px;
//     margin-bottom: 8px;
//     z-index: 999999;
//     position: absolute;
//     margin-bottom: 100px;
//     top: 0; */}
//                 <Grid item xs={12} md={6}>

//                     <FormProvider {...form}>
//                         <form onSubmit={form.handleSubmit(onSubmit)}>
//                             <Box
//                                 className="date-label"
//                                 sx={{
//                                     width: "50%",
//                                     paddingTop: 1,
//                                     display: "flex", // Add this to make the fields display inline
//                                     alignItems: "center",
//                                     justifyContent: "space-between", // Add this to make the fields take equal space

//                                 }}
//                             >
//                                 <span style={{ paddingRight: 2, paddingBottom: 10 }}>
//                                     <strong>From:</strong>
//                                 </span>
//                                 <InputDateField
//                                     source={"rangefrom"}
//                                     maxDate={new Date()}
//                                     sx={{ width: "45%" }} // Add this to reduce the width of each field
//                                 // style={{position:"fixed"}}
//                                 />
//                                 <strong
//                                     style={{
//                                         paddingRight: 2,
//                                         paddingLeft: 2,
//                                         paddingBottom: 10,
//                                         fontSize: "14px",
//                                         color: "#303030",
//                                         fontWeight: "inherit",
//                                     }}
//                                 >
//                                     &nbsp;To:&nbsp;
//                                 </strong>
//                                 <InputDateField
//                                     source={"rangeto"}
//                                     maxDate={new Date()}
//                                     sx={{ width: "45%" }} // Add this to reduce the width of each field
//                                 // style={{position:"fixed"}}
//                                 />
//                                 <MuiButton sx={{ mx: 1, marginBottom: 2 }} variant="contained" type="submit">
//                                     Apply
//                                 </MuiButton>
//                             </Box>

//                         </form>
//                     </FormProvider>
//                 </Grid>




//                 {/* Right side with search input, filter button, and add button */}
//                 <Grid
//                     item
//                     xs={12}
//                     md={6}
//                     container
//                     justifyContent="flex-end"
//                     alignItems="center"
//                 >
//                     <div className="group-btn">
//                         {/* Search Input */}
//                         <MuiTextField
//                             placeholder="search"
//                             InputProps={{
//                                 startAdornment: (
//                                     <InputAdornment position="start">
//                                         <SearchIcon />
//                                     </InputAdornment>
//                                 ),
//                             }}
//                             defaultValue={filterValues.q}
//                             onChange={handleSearchChange}
//                             variant="outlined"
//                             size="small"
//                         />
//                         {/* <FilterModal /> */}

//                         <button
//                             onClick={handleExportDocUploadManagementReport}
//                             type="button"
//                             className="g-line-btn"
//                             title="Export all data"
//                         >
//                             <svg
//                                 width="16"
//                                 height="17"
//                                 viewBox="0 0 16 17"
//                                 fill="none"
//                                 xmlns="http://www.w3.org/2000/svg"
//                             >
//                                 <g clip-path="url(#clip0_14375_6193)">
//                                     <path
//                                         d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"
//                                         fill="#2DC3E8"
//                                     />
//                                 </g>
//                                 <defs>
//                                     <clipPath id="clip0_14375_6193">
//                                         <rect
//                                             width="16"
//                                             height="16"
//                                             fill="white"
//                                             transform="translate(0 0.5)"
//                                         />
//                                     </clipPath>
//                                 </defs>
//                             </svg>
//                         </button>


//                     </div>
//                 </Grid>
//             </Grid>
//         </div>


//     );
// }