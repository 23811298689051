import moment from "moment";
import React from "react";
import { JSONTree } from "react-json-tree";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { checkModuleAccess, isSuperAdmin } from "../../Functions/permissions";
import { useDataProvider, useGetIdentity, usePermissions } from "react-admin";
import YesNoRadioButton from "../common/InputComponents/YesNoRadioButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import { useForm, FormProvider } from "react-hook-form"; // Import FormProvider and useForm
import InputTextArea from "../common/InputComponents/InputTextArea";
import FilePreview from "../common/FilePreview";
import FilePreviewThumnail from "../common/FilePreviewThumnail";

const ViewSecondaryAppView = () => {
  const location = useLocation();
  const state = location?.state;
  const navigate = useNavigate();

  // Create a form context using useForm
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <section className="main-sec">
        <div className="home_content">
          <div className="row">
            <div className="col-12 d-flex">
              <Breadcrumbs aria-label="breadcrumb">
                <Typography sx={{ fontWeight: "bold" }}>
                  <button
                    title="Go Back"
                    style={{ color: "#2cc0e4" }}
                    className="btn btn-link"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBack />
                  </button>
                  <span>View Secondary App</span>
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className="application-tabs">
            <div className="nav-flexes" style={{ marginTop: "30px" }}>


              <div>
                {state?.getSecondaryApp?.map((question) => (
                  question.display && (
                    <div key={question.ProfileTabFieldID}>
                      <br />
                      <YesNoRadioButton
                        key={question.ProfileTabFieldID}
                        required={question.required}
                        label={question.Question}
                        source={`response_${question.ProfileTabFieldID}`}
                        defaultValue={question?.response}
                        disable={true}
                      />

                      {
                        question?.response === "Yes" && question?.explanation ? (
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: "bold",
                                color: "#333",
                                marginBottom: "5px",
                              }}
                            >
                              Explanation:
                            </Typography>
                            <textarea
                              value={question.explanation}
                              disabled
                              rows={3}
                              style={{
                                width: "100%",
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                padding: "10px",
                                backgroundColor: "#f9f9f9",
                                color: "#666",
                                fontSize: "14px",
                                lineHeight: "1.5",
                                resize: "none",
                              }}
                            />
                          </Box>
                        ) : question?.files?.length > 0 ? (
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: "bold",
                                color: "#333",
                                marginBottom: "5px",
                              }}
                            >
                              File Preview:
                            </Typography>
                            <Box
                              sx={{
                                display: "flex", // Horizontal layout for all file items
                                flexWrap: "wrap", // Wrap items to the next row if necessary
                                gap: "16px", // Spacing between items
                                marginBottom: "10px", // Space below the group
                              }}
                            >
                              {question.files.map((file, index) => (
                                <Box key={index}
                                  sx={{
                                    display: "flex",

                                  }}>
                                  <FilePreviewThumnail
                                    doc_id={undefined}
                                    img={file}
                                    tag={undefined}
                                    invalidateQueries={undefined}
                                  />


                                </Box>


                              ))}
                            </Box>
                          </Box>
                        ) : null
                      }

                    </div>
                  )

                ))}


              </div>

            </div>
          </div>
        </div>
      </section>
    </FormProvider>
  );
};

export default ViewSecondaryAppView;
