import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import InputField2 from "../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import ToggleButtons from "../common/InputComponents/ToggleButtons";
import ClearIcon from "@mui/icons-material/Clear";

import { Box, Button, duration } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
    Loading,
    useCreate,
    useGetIdentity,
    useGetList,
    useGetOne,
    useNotify,
    useRefresh,
    useStore,
} from "react-admin";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import save_icon from "../../../assets/images/save_icon.svg";
import formValidation from "../../yupFormValidationRules/formValidator";
import InputSelectField from "../common/InputComponents/InputSelectField";
import { CircularProgress } from "@mui/material";
import InputDateField from "../../componets/common/InputComponents/InputDateFieldOld";
import { AgentList, FloorPlan } from "../Ats/NewApplicationModel";
import { JSONTree } from "react-json-tree";
import moment from "moment";

const CreateNewSchedule = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const toggleButton = [
        {
            value: 0,
            label: "Inactive",
            icon: <ClearIcon />,
            borderColor: "#909090",
            backgroundColor: "#EEE",
            boxShadowColor: "#90909033",
            color: "#606060",
        },
        {
            value: 1,
            label: "Active",
            icon: <CheckIcon />,
            borderColor: "#46A96F",
            backgroundColor: "#EBFFF3",
            boxShadowColor: "#46a96f1a",
            color: "#46A96F",
        },
    ];
    const { data: getScheduleReport, isLoading: lodingcheduleReport } = useGetList(
        "getScheduleReport",
        {
            filter: {
                SubscriberId: switchSubscriberObj?.value,
                // id: [id],
                // UserType: UserType,
            },
        }
    );
    const isRealEstate = switchSubscriberObj?.companyType;
    const names = getScheduleReport?.map(record => record.Name);

    const validationSchema = yup.lazy((value) =>
        yup.object().shape({
            scheduleName: yup.string()
                .required('Name is required')
                .test(
                    'unique-name', // Custom validation name
                    'Name must be unique', // Validation error message
                    (value) => {
                        // Check if the value already exists in the names array
                        return !names?.includes(value);
                    }
                ),
            active: formValidation.StringRequiredValidation,
            reportType: formValidation.validationForDropDown,

            //  yup
            //     .object()
            //     .when("isRealEstate", {
            //         is: (isRealEstate) => {
            //             // Add the debugger here to inspect `isRealEstate` value during validation

            //             return isRealEstate === true; // Check if isRealEstate is true (boolean)
            //         },
            //         then: yup.object().typeError("Required").required("Required!"),
            //         otherwise: yup.object().nullable(), // Allow null if not required
            //     }),
              office: yup.array()
                            .of(
                                yup.object().shape({
                                    value: yup.string().required("Office value is required"),
                                    label: yup.string().required("Office label is required"),
                                })
                            )
                            .when("isRealEstate", {
                                is: "Real Estate",
                                then: yup.array()
                                    .min(1, "Office is required") // At least one object is required in the array
                                    .required("Office is required"),
                                otherwise: yup.array().nullable(),
                            }),
            // office: isRealEstate == "Real Estate" ? formValidation.validationForDropDown : "",
            // property: isRealEstate == "Real Estate" ? "" : formValidation.validationForDropDown,
           property: yup.array()
                     .of(
                       yup.object().shape({
                         value: yup.string().required("property value is required"),
                         label: yup.string().required("property label is required"),
                       })
                     )
                     .when("isRealEstate", {
                       is: "Property Management",
                       then: yup.array()
                         .min(1, "Property is required") // At least one object is required in the array
                         .required("Property is required"),
                       otherwise: yup.array(),
                     }),
            // yup
            //     .object()
            //     .when("isRealEstate", {
            //         is: (isRealEstate) =>
            //             isRealEstate == "Property Management",
            //         then: yup.object().typeError("Required").required("Required!"),
            //         otherwise: yup.object().nullable(),
            //     }),


            reportDuration: formValidation.validationForDropDown,
            frequency: formValidation.validationForDropDown,
            day: yup
                .object()
                .when("frequency", {
                    is: (frequency) => frequency?.value == "Weekly",
                    then: yup.object().typeError("Required").required("Required!"),
                    otherwise: yup.object().nullable(), // Not required if usertype is not "Agent"
                }),
            date: yup
                .string()
                .when("frequency", {
                    is: (frequency) => (frequency?.value === "Monthly" && frequency?.value === "Yearly"),
                    then: yup.string().required("Required!").typeError("Required!"),
                    otherwise: yup.string().nullable(),
                })
                // .required("Required!") // This ensures the field is always required
                .test("dateRange", "Must be between 1 and 31 days", (value) => {
                    if (!value) return true; // Allow empty if it's nullable
                    const numValue = parseInt(value, 10);
                    return numValue >= 1 && numValue <= 31;
                }),
            month: yup
                .object()
                .when("frequency", {
                    is: (frequency) => frequency?.value == "Yearly",
                    then: yup.object().typeError("Required").required("Required!"),
                    otherwise: yup.object().nullable(), // Not required if usertype is not "Agent"
                }),
            StartDate: formValidation.date,
            // endDate: formValidation.date,
            sentto: yup
                .array()
                .min(1, "At least one office is required!") // Requires at least one item
                .of(
                    yup.object().shape({
                        value: yup.string().required("Required!"),
                        label: yup.string().required("Required!"),
                    })
                )
                .required("Required!"),

        })
    );


    const defaultFormValues = {
        scheduleName: "",
        reportType: "",
        active: 1,
        office: "",
        RoleNames: "",
        isRealEstate: "",
        user: "",
        floor: "",
        unit: "",
        reportDuration: "",
        frequency: "",
        day: "",
        date: "",
        month: "",
        StartDate: "",
        endDate: "",
        sentto: "",
    };

    const { id } = useParams();
    const methods = useForm({
        defaultValues: defaultFormValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange",

    });
    const {
        register,
        handleSubmit,
        reset,
        watch,
        // control,
        setValue,
        getValues,
        trigger,
        formState: { errors },
    } = methods;
    const { data: identity } = useGetIdentity();
    const { data: getusersetup, isLoading: loadingUserSetupDetails } = useGetList(
        "user-setup",
        {
            filter: {
                SubscriberId: switchSubscriberObj?.value,
                // id: [Id],
                UserType: "All",
                propertyStatus: "Active",
            },
        }
    );
    const { data: applicationDetail, isLoading: isLoadingData } = useGetOne(
        "getnewapplicationdetail",
        {
            id: switchSubscriberObj?.value,
        },
        // { enabled: isSuperUser ? (subscriberId ? true : false) : id ? true : false }
    );
    const selectedOfficeIds =  methods.watch<any>('office') ? methods.watch<any>('office')?.map((item: any) => item?.value): null
    const userRole = useSelector((state: any) => state?.users?.role) || identity?.userrole;
    let filterData: any = [];
  
    // const office1= methods?.watch<any>().office?.label;
    if (methods.watch().office !== null) {
        let filter = applicationDetail?.Offices?.filter((office: any) =>
            selectedOfficeIds?.includes(office.ID)
        );
        filterData = filter;
    }
    // call get agent list data
    const adminurl = filterData?.map((item) => item?.URL);
    const agenturl = applicationDetail?.UserURL;
    const { data: agentdata, error } = useGetList(
        "getagentlist",
        {

            filter: {
                url:
                    userRole === "Agent" ? agenturl : adminurl, SubscriberID: filterData?.[0]?.SubscriberID
            },
        },
        { enabled: adminurl || agenturl && filterData?.[0]?.SubscriberID ? true : false }
    );
    const selectedPropertyIds = methods.watch<any>('property')?.map((item: any) => item?.value);
    let filterData1: any = [];
    if (methods.watch<any>("property") !== null) {

        let filter1 = applicationDetail?.Properties?.filter((property: any) =>
            selectedPropertyIds?.includes(property?.ID)
        );
        filterData1 = filter1;
    }

    const { data: getfloor } = useGetList(
        "getfloorlist",
        {
            filter: {
                PropertyID: filterData1?.map((item) => item?.ID),
                SubscriberID: filterData1?.[0]?.SubscriberID,
            },
        },
        { enabled: (filterData1?.map((item) => item?.ID) && filterData1?.[0]?.SubscriberID) ? true : false }
    );
    //unit list
    const selectedFloorIds = Array.isArray(methods.watch("floor"))
        ? methods.watch<any>("floor")?.map((item: any) => item?.value)
        : [];
    let filterData2: any = [];
    if (methods.watch<any>("property") !== null) {
        let filter2 = getfloor?.filter((property: any) =>
            selectedFloorIds?.includes(property?.ID)
        );
        filterData2 = filter2;
    }
    console.log(methods.watch<any>("floor"), filterData2, "sdsd22")
    // call get unit list data
    const { data: getunit } = useGetList(
        "getunitlist",
        {
            filter: {
                PropertyID: filterData2?.map((item) => item?.ID),
                SubscriberID: filterData2?.[0]?.SubscriberID,
            },
        },
        { enabled: filterData2?.map((item) => item?.ID) && filterData2?.[0]?.SubscriberID ? true : false }
    );
    const weeks = [
        {
            week: "Sunday",
            id: 1,
        },
        {
            week: "Monday",
            id: 2,
        },
        {
            week: "Tuesday",
            id: 3,
        },
        {
            week: "Wednesday",
            id: 4,
        },
        {
            week: "Thursday",
            id: 5,
        },
        {
            week: "Friday",
            id: 6,
        },
        {
            week: "Saturday",
            id: 7,
        },
    ]
    const role = [
        {
            role: "Agent",
            id: 1,
        }
    ]
    const months = [
        {
            monthName: "January",
            id: 1,
        },
        {
            monthName: "February",
            id: 2,
        },
        {
            monthName: "March",
            id: 3,
        },
        {
            monthName: "April",
            id: 4,
        },
        {
            monthName: "May",
            id: 5,
        },
        {
            monthName: "June",
            id: 6,

        },
        {
            monthName: "July",
            id: 7,

        },
        {
            monthName: "August",
            id: 8,

        }, {
            monthName: "september",
            id: 9,

        }, {
            monthName: "October",
            id: 10,

        }, {
            monthName: "November",
            id: 11,

        }, {
            monthName: "December",
            id: 12,

        },
    ]
    const ReportType = [
        {
            report: "Revenue Management Report",
            id: 1,
        },
        {
            report: "Docupload Management Report",
            id: 2,
        },
        {
            report: "Lead Management Report",
            id: 3,
        },
    ]
    const durationofReport = [
        {
            DurationReport: "Monthly",
            id: 1,
        },
        {
            DurationReport: "quarterly",
            id: 2,
        },
        {
            DurationReport: "Yearly",
            id: 3,
        }
    ]
    const duration = [
        {
            Duration: "Weekly",
            id: 1,
        },
        {
            Duration: "Monthly",
            id: 2,
        },
        {
            Duration: "Yearly",
            id: 3,
        }
    ]
    // const {
    //     data: getrole,
    //     isLoading: loadingrole,
    // } = useGetList(
    //     "get-role",
    //     {
    //         filter: {
    //             subscriberid: switchSubscriberObj?.value,
    //             //id: id,
    //         },
    //     },
    // );
    const [create, isLoading] = useCreate();
    const { data } = JSON.parse(localStorage.getItem("auth") || "");
    // form submit handler
    const onSubmit = (value) => {
        console.log(value, "dzhjsd")
        // const roles = value?.RoleNames?.map((item) => item.label).join(",");
        // const username = value?.user?.map((item) => item.value).join(",");
        const addData = {
            // ...value,
            // ID: 0,
            Name: value?.scheduleName,
            SubscriberID: parseInt(switchSubscriberObj?.value),
            RealEstate: isRealEstate == "Real Estate" ? 0 : 1,
            ReportType: value?.reportType?.label,
            OfficeIDs: value?.office ? value?.office?.map((item) => item.value).join(",") : null,
            PropertyIDs: value?.property ? value?.property?.map((item) => item.value).join(",") : null,
            RoleNames: value?.RoleNames ? value?.RoleNames?.map((item) => item.label).join(",") : null,
            UserIDs: value?.user ? value?.user?.map((item) => item.value).join(",") : null,
            ReportDuration: value?.reportDuration?.label,
            Frequency: value?.frequency?.label,
            Day: value?.day?.value ? parseInt(value?.day?.value) : null,
            Date: value?.date ? parseInt(value?.date) : null,
            Month: value?.month?.value ? parseInt(value?.month?.value) : null,
            FloorIDs:value?.floor ? value?.floor?.map((item) => item.value).join(",") : null,
            UnitIDs: value?.unit ? value?.unit?.map((item) => item.value).join(",") : null,
            StartDate: value?.StartDate ? moment.utc(value.StartDate).format('MM/DD/YYYY') : "",
            EndDate: value?.endDate ? moment.utc(value.endDate).format('MM/DD/YYYY') : null,
            SendTo: value?.sentto?.map((item) => item.value).join(","),
            Status: parseInt(value?.active),
        };

        console.log(addData, "addData")
        create(
            `add-schedule-report`,
            { data: addData },
            {
                onSuccess: (data) => {
                    notify(


                        `Report schedule successfully.`,


                        {
                            type: "success",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                        }
                    );
                    refresh();
                    methods?.reset();
                    navigate(-1);
                },
                onError: (e: any) => {
                    notify(`Operation fail:${e.message}`, {
                        type: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    })

                },


            }
        );

    };

    const start_date = methods.watch("StartDate");

    return (
        <Fragment>
            <div className="home_content">
                <div className="row">
                    <div className="col-12 d-flex">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography sx={{ fontWeight: "bold" }}>
                                <button
                                    title="Go Back"
                                    style={{ color: "#2cc0e4" }}
                                    className="btn btn-link"
                                    onClick={() => navigate(-1)}
                                >
                                    <ArrowBack />
                                </button>

                                <span>Add New Schedule</span>

                            </Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
                        {/* <JSONTree data={isRealEstate}/> */}
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="Schedule Name"
                                    source={`scheduleName`}
                                    placeholder="Enter Schedule Name"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label="Report Type"
                                    source={`reportType`}
                                    options={
                                        ReportType?.map((item, index) => ({
                                            label: item?.report,
                                            value: item?.report,
                                        }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    stopAutoFocus={true}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <ToggleButtons
                                    toggleButton={toggleButton}
                                    label="Status"
                                    labelClass={"lable-title"}
                                    source={`active`}
                                />
                            </div>
                            {/* </div> */}
                            {/* <div className="row"> */}
                            {applicationDetail?.Offices?.length > 0 && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label="Office"
                                    source={`office`}
                                    options={applicationDetail?.Offices?.map(
                                        (item) => ({
                                            label: item.OfficeName,
                                            value: item.ID,
                                        })
                                    )}
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    onChangeCallback={() => {
                                        setValue("RoleNames", "")
                                        setValue("user", "")

                                    }}
                                    multiple={true}
                                />
                            </div>}


                            {methods.watch<any>("office") &&
                                <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                    <InputSelectField
                                        label={`Role`}
                                        source={`RoleNames`}
                                        options={
                                            role?.map((item, index) => ({
                                                label: item?.role,
                                                value: item?.id,
                                            }))
                                        }
                                        labelAsteriskClass={"color-red"}
                                        labelClass={"lable-title"}
                                        requiredClass={{
                                            fontSize: "70%",
                                        }}
                                        stopAutoFocus={true}
                                        multiple={true}
                                        required={false}

                                    />
                                </div>}
                            {methods.watch<any>("office") && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label="User"
                                    source={`user`}
                                    options={agentdata?.map((item) => ({
                                        label: item.FullName,
                                        value: item?.UserID
                                        // value: item.ASPNetUserID,
                                        // id: item.id,
                                    }))}
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    multiple={true}
                                    required={false}
                                />
                            </div>}




                            {/* </div> */}




                            {/* <div className="row"> */}
                            {applicationDetail?.Properties?.length > 0 && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label="Property"
                                    source={`property`}
                                    options={applicationDetail?.Properties?.map(
                                        (item) => ({
                                            label: item.PropertyName,
                                            value: item.ID,
                                        })
                                    )}
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    onChangeCallback={() => {
                                        setValue("floor", "");
                                        setValue("unit", "");
                                    }}
                                    required={true}
                                    multiple={true}
                                />
                            </div>}

                            {methods.watch<any>("property") && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label="Select Floor Plan"
                                    source={`floor`}
                                    options={getfloor?.map((item) => ({
                                        label: item.FloorCode,
                                        value: item.id,
                                    }))}
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    multiple={true}
                                    required={false}
                                    onChangeCallback={() => setValue("unit", "")}
                                />
                            </div>}
                            {methods.watch<any>("floor") && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label="Unit"
                                    source={`unit`}
                                    options={getunit?.map((item) => ({
                                        label: item.UnitName,
                                        value: item.ID,
                                    }))}
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    multiple={true}
                                    required={false}
                                />
                            </div>}




                            {/* </div> */}


                            {/* <div className="row"> */}
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Report Duration`}
                                    source={`reportDuration`}
                                    options={
                                        durationofReport?.map((item, index) => ({
                                            label: item?.DurationReport,
                                            value: item?.DurationReport,
                                        }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    stopAutoFocus={true}
                                />

                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Schedule Frequency`}
                                    source={`frequency`}
                                    options={
                                        duration?.map((item, index) => ({
                                            label: item?.Duration,
                                            value: item?.Duration,
                                        }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    stopAutoFocus={true}
                                    onChangeCallback={(event) => {

                                        methods.setValue("day", "");
                                        methods.setValue("date", "");
                                        methods.setValue("month", "");
                                    }}
                                />

                            </div>

                            {(methods.watch<any>("frequency")?.label == "Weekly") && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">

                                <InputSelectField
                                    label={`Day`}
                                    source={`day`}
                                    options={
                                        weeks?.map((item, index) => (

                                            {
                                                value: item.id,
                                                label: item?.week,
                                            }
                                        ))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    stopAutoFocus={true}
                                />

                            </div>}


                            {/* </div> */}

                            {/* <div className="row"> */}
                            {(methods.watch<any>("frequency")?.label == "Monthly" || methods.watch<any>("frequency")?.label == "Yearly") && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="Date"
                                    source={`date`}
                                    placeholder="Enter date"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                />

                            </div>}
                            {(methods.watch<any>("frequency")?.label == "Yearly") && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Month`}
                                    source={`month`}
                                    options={
                                        months?.map((item, index) => ({
                                            label: item?.monthName,
                                            value: item?.id,
                                        }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    // required={false}
                                    multiple={false}
                                />

                            </div>}
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputDateField
                                    source={`StartDate`}
                                    label="Start Date"
                                    labelClass={"lable-title"}

                                    minDate={new Date()}
                                />

                            </div>

                            {/* </div> */}
                            {/* <div className="row"> */}
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputDateField
                                    source={`endDate`}
                                    label="End Date"
                                    labelClass={"lable-title"}
                                    notRequired={true}
                                    minDate={start_date ? moment(start_date).toDate() : new Date()}
                                />

                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Sent to`}
                                    source={`sentto`}
                                    options={
                                        getusersetup?.map((item, index) => ({
                                            label: item?.Name,
                                            value: item?.Email,
                                        }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}

                                    multiple={true}
                                />

                            </div>
                        </div>


                        <Box

                            display={"flex"}
                            justifyContent={"end"}
                            alignItems={"center"}
                            className="mt-4"
                            minHeight="10vh"
                        >
                            <Box>
                                <Button
                                    sx={{ mx: 1 }}
                                    onClick={() => {
                                        methods?.reset();
                                        navigate(-1);
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                    type="button"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    startIcon={isLoading?.isLoading ? "" : <CheckIcon />}
                                    sx={{ mx: 1 }}
                                    variant="contained"
                                    type="submit"

                                >

                                    {isLoading?.isLoading ? (
                                        <CircularProgress size="20px" sx={{ color: "white" }} /> // Show loader when isLoading is true
                                    ) : (
                                        <span>Create Schedule</span> // Show text when isLoading is false
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </FormProvider>
            </div>

        </Fragment>
    );
};

export default CreateNewSchedule;



