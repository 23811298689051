import FilterReports from "../../componets/SubscriberManagementReport/FilterReports";
import { Datagrid, FunctionField, ListBase, ListToolbar, Pagination, Resource, TextField } from 'react-admin';
import ExportScreenReport from "../../componets/SubscriberManagementReport/ExportScreenReport";
import FilterChips from "../../componets/Ats/SuperAdminInbox/FilterChips";
import moment from "moment";
import { NumericFormat } from "react-number-format";
const AdminScreeningManagement = () => {
    return <>
        <Resource name="adminScreenManagement" list={AdminScreenmanagementList} />
    </>
}
const MyList = ({ children, filters, actions, ...props }) => {



    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
        >
            <ListToolbar
                filters={filters}
                actions={actions}
            />

            <FilterChips excludeKeys={['app_invitation_type','rangeto','rangefrom']} />
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};

export const AdminScreenmanagementList = () => {
    return (
        <>
            <br />
            <br />
            <MyList
                filters={<FilterReports />}
                actions={<ExportScreenReport />}
                sort={{ field: "ApplicationNumber", order: "DESC" }}
                children={<DataGrid />}
            />

        </>
    )
}

const DataGrid = () => {
    return (<Datagrid bulkActionButtons={false} sx={{
        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        "& .RaDatagrid-expandIcon": { color: "#fff" },
        "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
        "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
        },
        "& .RaDatagrid-table": {
            fontSize: 12,
        },
        "& .MuiToggleButton-root.Mui-selected": {
            fontWeight: 700,
        },
        marginTop: '40px'
    }}>
        <TextField source="ApplicationNumber" label={"App Id"} />
        <FunctionField
            sortBy="AppDate"
            label="Date"
            render={(record) => {
                // Format the AppDate using moment.js
                const formattedDate = moment.utc(record.AppDate).format('MM/DD/YYYY');

                return (
                    <>

                        <p>{formattedDate}</p> {/* Display the formatted AppDate */}
                    </>
                );
            }}
        />
        <TextField source="SubscriberName" label={"Subscriber"} />
        <TextField source="PropertyName/OfficeName" label={"Property/Office"} />
        <TextField source="ApplicantName" label={"Applicant"} />
        {/* <TextField source="ApplicationFee" label={"Applicant Fee"}/> */}
        <FunctionField
            source="ApplicationFee"
            label="Applicant Fee"
            sortBy="ApplicationFee"
            render={(record) => {
                return !record.ApplicationFee ? (
                    ""
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.ApplicationFee}
                        />

                    </>
                );
            }}
        />
        {/* <TextField source="ScreeningFee" label={"Screening Fee"} /> */}
        <FunctionField
            source="ScreeningFee"
            label="Screening Fee"
            sortBy="ScreeningFee"
            render={(record) => {
                return !record.ScreeningFee ? (
                    ""
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.ScreeningFee}
                        />

                    </>
                );
            }}
        />
        <TextField source="ScreeningCompanyName" label={"Screening Company"} />


        <TextField source="ScreeningCompanyAccountNumber" label={"Account Number"} />
    </Datagrid>)


}
export default AdminScreeningManagement;