import FilterReports from "../../componets/SubscriberManagementReport/FilterReports";
import { Button, Datagrid, DateField, FunctionField, List, ListBase, ListToolbar, NumberField, Pagination, Resource, TextField, useListContext, useStore } from 'react-admin';
import {
    Box, TextField as MuiTextField,
    Button as MuiButton, Grid
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ExportAdminLead from "../../componets/SubscriberManagementReport/ExportAdminLead";
import FilterChips from "../../componets/Ats/SuperAdminInbox/FilterChips";
import moment from "moment";
import { NumericFormat } from "react-number-format";
const AdminLeadManagement = () => {
    return <>
        <Resource name="adminLeadManagement" list={AdminleadmanagementList} />
    </>
}

const bgStyle = (condition: string) => {
    let style = {};
    switch (condition) {
        case "CompletedConditionallyApproved":
            style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
            break;
        case "Completed-Unacceptable":
            style = { backgroundColor: "#F8ECE2", color: "#FF4848" };
            break;
        case "Completed-Approved":
            style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
            break;
        case "Invited":
            style = { backgroundColor: "#E9E9E9", color: "#909090" };
            break;
        case " In Progress":
        case "Application In Progress":
            style = { backgroundColor: "#DDF8FF", color: "#1CA2C2" };
            break;
        case "Submitted":
        case "Review Pending":
            style = { backgroundColor: "#B0EFFF", color: "#127790" };
            break;
        // case "Screening":
        case "Completed":
        // case "Completed-Approved":
        case "Verified":
            style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
            break;
        case "Cancelled":
        case "Archived":
        // case "Completed-Unacceptable":
        case "Unverifiable":
            style = { backgroundColor: "#FFEEEE", color: "#FF4848" };
            break;
        case "Completed-Conditionally Approved":
        case "CompletedConditionallyApproved":
        case "Further Documentation Required":
        case "Further Doc Req.":
            style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
            break;
        default:
            style = {};
    }
    return style;
};
const MyList = ({ children, filters, actions, ...props }) => {



    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
        >
            <ListToolbar
                filters={filters}
                actions={actions}
            />

            <FilterChips excludeKeys={['app_invitation_type','rangeto','rangefrom']} />
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};
// const page=<Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
export const AdminleadmanagementList = () => {
    return (
        <>
            <br />
            <br />
            <MyList
                filters={<FilterReports />}
                actions={<ExportAdminLead />}
                sort={{ field: "ApplicationNumber", order: "DESC" }}
                // filterDefaultValues={{ subscriberID: 1 }}
                children={<DataGrid />}
            />
        </>
    )
}
const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
    }
    return text;
};

// <List filters={<FilterReports/>} actions={<ExportAdminLead/>} pagination={page} perPage={100}sx={{marginTop: '20px'}}>
const DataGrid = () => {
    return (<Datagrid bulkActionButtons={false} sx={{
        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        "& .RaDatagrid-expandIcon": { color: "#fff" },
        "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
        "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
        },
        "& .RaDatagrid-table": {
            fontSize: 12,
        },
        "& .MuiToggleButton-root.Mui-selected": {
            fontWeight: 700,
        },
        marginTop: '40px',

    }}>
        {/* <TextField source="id" /> */}
        <TextField source="ApplicationNumber" label={'APP ID'} />
        <FunctionField
            sortBy="AppDate"
            label="Date"
            render={(record) => {
                // Format the AppDate using moment.js
                const formattedDate = moment.utc(record.AppDate).format('MM/DD/YYYY');

                return (
                    <>

                        <p>{formattedDate}</p> {/* Display the formatted AppDate */}
                        {/* <p>{record.LandLordPhone}</p> */} {/* Other data rendering if needed */}
                    </>
                );
            }}
        />
        <TextField source="FirstName" />
        <TextField source="LastName" />
        <FunctionField
            label={"Status"}
            source="ApplicationStatus"
            render={(record) => {
                return (
                    <MuiButton
                        sx={{ minWidth: "80%" }}
                        title={record.ApplicationStatus}
                        variant="contained"
                        disableElevation={true}
                        style={bgStyle(record.ApplicationStatus)}
                    >
                        {truncateText(record.ApplicationStatus, 12)}
                    </MuiButton>
                );
            }}
        />
        <TextField source="Mobile" />
        <TextField source="Address" />
        <TextField source="City" />
        <TextField source="State" />
        <TextField source="ZipCode" />
        <FunctionField label="Current LandLord" render={(record) => {
            return (<>
                <p>{record.LandLordName || 'NA'}</p>
                <p>{record.LandLordPhone}</p>
            </>)
        }
        } />
        <DateField source="MoveInDate" />
        <FunctionField
            source="Rent"
            label="Rent"
            sortBy="Rent"
            render={(record) => {
                return !record.DesiredRent ? (
                    "NA"
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.DesiredRent}
                        />

                    </>
                );
            }}
        />
        <FunctionField
            source="MonthlyIncome"
            label="Monthly Income"
            sortBy="MonthlyIncome"
            render={(record) => {
                return !record.MonthlyIncome ? (
                    "NA"
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.MonthlyIncome}
                        />

                    </>
                );
            }}
        />
        <FunctionField
            source={"Score"}
            label="Score"
            render={(record) => {
                return (
                    <>
                        {record.Score > 0 && (
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                            >
                                <Avatar
                                    sx={{
                                        bgcolor:


                                            record.ScreeningDetails ===
                                                "ConditionallyApproved" ||
                                                record.ScreeningDetails ===
                                                "Conditionally Approved"
                                                ? "#FFF8E2"
                                                : record.ScreeningDetails === "Unacceptable"
                                                    ? "#F8ECE2"
                                                    : record.ScreeningDetails ===
                                                        "NoAssessment" ||
                                                        record.ScreeningDetails === "No Assessment"
                                                        ? "#fff"
                                                        : record.ScreeningDetails === ""
                                                            ? "inherit"
                                                            : "#E6FDF0",
                                        color:
                                            // record?.ApplicationStatus ===
                                            //   "Completed-Approved" ||
                                            // record?.ApplicationStatus ===
                                            //   "CompletedConditionallyApproved" ||
                                            // record?.ApplicationStatus ===
                                            //   "Completed-Unacceptable"
                                            //   ? record?.ApplicationStatus ===
                                            //     "Completed-Approved"
                                            //     ? "#46A96F"
                                            //     : record?.ApplicationStatus ===
                                            //       "CompletedConditionallyApproved"
                                            //     ? "#F9BB04"
                                            //     : record?.ApplicationStatus ===
                                            //       "Completed-Unacceptable"
                                            //     ? "#FF4848"
                                            //     : "FF4848"
                                            //   :
                                            record.ScreeningDetails ===
                                                "ConditionallyApproved" ||
                                                record.ScreeningDetails ===
                                                "Conditionally Approved"
                                                ? "#F9BB04"
                                                : record.ScreeningDetails === "Unacceptable"
                                                    ? "#FF4848"
                                                    : record.ScreeningDetails == "NoAssessment" ||
                                                        record.ScreeningDetails === "No Assessment"
                                                        ? "#000000"
                                                        : record.ScreeningDetails === ""
                                                            ? "inherit"
                                                            : "#46A96F",
                                        border:
                                            // record?.ApplicationStatus ===
                                            //   "Completed-Approved" ||
                                            // record?.ApplicationStatus ===
                                            //   "CompletedConditionallyApproved" ||
                                            // record?.ApplicationStatus ===
                                            //   "Completed-Unacceptable"
                                            //   ? record?.ApplicationStatus ===
                                            //     "Completed-Approved"
                                            //     ? "1px solid #46A96F"
                                            //     : record?.ApplicationStatus ===
                                            //       "CompletedConditionallyApproved"
                                            //     ? "1px solid #F9BB04"
                                            //     : record?.ApplicationStatus ===
                                            //       "Completed-Unacceptable"
                                            //     ? "1px solid #FF4848"
                                            //     : ""
                                            //   :
                                            record.ScreeningDetails ===
                                                "ConditionallyApproved" ||
                                                record.ScreeningDetails ===
                                                "Conditionally Approved"
                                                ? "1px solid #F9BB04"
                                                : record.ScreeningDetails === "Unacceptable"
                                                    ? "1px solid #FF4848"
                                                    : record.ScreeningDetails ===
                                                        "NoAssessment" ||
                                                        record.ScreeningDetails === "No Assessment"
                                                        ? "1px solid #000000"
                                                        : record.ScreeningDetails === ""
                                                            ? "inherit"
                                                            : "1px solid #46A96F",
                                    }}
                                >

                                    {record?.Score}
                                </Avatar>
                            </Box>

                        )}
                    </>
                );
            }}
        />




        <TextField source="LeadSource" />


    </Datagrid>

    )
}
export default AdminLeadManagement;