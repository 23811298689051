//Create Edit form with yup field messsage , submit button and cancel button
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { useCreate, useGetList, useNotify, useRefresh } from "react-admin";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputTextArea from "../../componets/common/InputComponents/InputTextArea";
import { toast } from "react-toastify";

const CreatePredefinedMessage = () => {   
    const navigate = useNavigate();
    const notify = useNotify();
    const [create, { isLoading: createLoading, error: createError }] = useCreate();

    const methods = useForm({
        resolver: yupResolver(
            yup.object().shape({
                Message: yup.string().required("Message is required"),
            })
        ),
    });

    const onSubmit = (data: any) => {
        create("predefinedmessage", { data: data }, {
            onSuccess: (data) => {
                notify(`Predifined message added successfully.`, { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
             navigate(-1);
            }
        });
    };
    
    return (
        <div className="container mt-5"  >
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <h3 className="form-title">Create Predefined Message</h3>
                    <br />
                    <InputTextArea
                        register={`Message`}
                        label="Type your message here"
                        placeholder="Type your message here"
                        labelClass={"lable-title"}
                        labelAsteriskClass={"color-red"}
                        additionalInputClass={"cus-control"}
                        styleProperty={{ height: "auto" }}
                        messageText={false}
                        requiredClass={{
                          fontSize: "70%",
                        }}
                        rows={4}
                    />
                    <br />
                    <Button type="submit" variant="contained">
                        Submit
                    </Button>
                    <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </Button>
                </form>
            </FormProvider>
        </div>
    );
};

export default CreatePredefinedMessage;