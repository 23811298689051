import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import placeholderImg from '../../assets/images/placeholder-payslip.png';
import dlt from "../../assets/images/dlt.svg";
import { useDataProvider, useNotify } from 'react-admin';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import * as ImageTracer from "imagetracerjs";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteContainedBtn from './DeleteContainedBtn';
import DeleteFileConfirmBtn from './DeleteFileConfirmBtn';


export default function FilePreviewThumnail({ img, alt = '', doc_id, file_name = '', isId = false,
  tag,
  invalidateQueries, file = img
}) {
  // 
  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const notify = useNotify();

  // ImageTracer.imageToSVG( img, ImageTracer.appendSVGString, { ltres:0.1, qtres:1, scale:10, strokewidth:5 });
//   ImageTracer.loadImage(
//    img,
//     function(canvas){ 
//       var imgd = ImageTracer.getImgdata( canvas );
//       
//       
      
//      }
// );

// ImageTracer.imageToTracedata(
//   img,
//   function(tracedata){ alert( JSON.stringify( tracedata ) ); },
//   { ltres:0.1, qtres:1, scale:10 }
// );
 
  return (
    <>
      {img &&
        <div className="d-inline-flex flex-column flex-wrap">
          <img
            src={img}
            style={{ maxWidth: '80px' }}
            className={'img-thumbnail ratio ratio-1x1'}
            alt=""
            onClick={() => window.open(file, '_blank')}
          />
          <div className='' style={{ textAlign: 'center' }}>
            <p className='small pt-1' title={file_name} style={{ textAlign: 'center', fontSize: 10 }}>{file_name ? `${file_name.substring(0, 9)}...` : img && img.split('/').pop().split('_').pop().substring(0, 15)+'...'}</p>
           <DeleteFileConfirmBtn doc_id={doc_id}  invalidateQueries={invalidateQueries} tag={tag}  />
          </div>

        </div>}
    </>
  )
}


