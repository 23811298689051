import React from 'react'

import DeleteIcon from '@mui/icons-material/Delete';
import { useDataProvider, useNotify, useRefresh,Confirm } from 'react-admin';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { setUploadPetImageId } from '../../Store/Slice/applicantDetailsSlice';

export default function DeleteFileConfirmBtn({ doc_id, isArrayRemove = false, index = 1, invalidateQueries, tag, ...props }) {
    const dataProvider = useDataProvider();
    const queryClient = useQueryClient();
    const notify = useNotify();
    const refresh=useRefresh();
    const dispatch = useDispatch();
    const { mutate, isLoading, isSuccess } = useMutation(tag, (id) => dataProvider.deleteDocument(id), {
        onSuccess: (data) => {
            setConfirmDelete(false);
            queryClient.invalidateQueries([`${invalidateQueries}`])
            refresh();
            notify('Document deleted successfully...', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
            dispatch(
                setUploadPetImageId({
                  index: props?.petIndex,
                  id: doc_id,
                  type: "DELETE",
                })
              );
            if(props?.handleOnChange){
              props?.handleOnChange();
            }
        },
        onError: (data) => {
            setConfirmDelete(false);
            alert("Fail deleting document")
        },
        onMutate: () => {
            notify('Document is Deleting...', { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        }
    })

    const { mutate: deleteDocumentNoDoc, isLoading: deleteDocumentNoDocIsLoading } = useMutation(tag, (id) => dataProvider.deleteDocumentNoDoc(id), {
        onSuccess: (data) => {
            setConfirmDelete(false);
            queryClient.invalidateQueries([`${invalidateQueries}`])
            props?.handleOnChange();
            notify('Document deleted successfully...', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        },
        onError: (data) => {
            setConfirmDelete(false);
            alert("Fail deleting document")
        },
        onMutate: () => {
            notify('Document is Deleting...', { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        }
    })
    const handleDeleteDocument = (id) => {
        if (isArrayRemove)
            return deleteDocumentNoDoc(id)
        mutate(id)
    }
    const [confirmDelete, setConfirmDelete] = React.useState(false);
    return (
        <>
            <div className='print-hide' style={{ textAlign: 'center' }}>
                <a
                    type='button'
                    href={'#'}
                    onClick={(e) => {
                        e.preventDefault();
                        setConfirmDelete(true)
                    }}
                    className="btn btn-link" style={{ color: 'red', textAlign: 'center', fontSize: 10 }}
                ><DeleteIcon style={{ fontSize: 12 }} /> Delete</a>
            </div>
           
                    
                    <Confirm
                      isOpen={confirmDelete}
                      // loading={isLoading}
                      sx={{
                        "& .RaConfirm-iconPaddingStyle": { display: "none" },
                      }}
                      title="Confirm"
                      content={
                        "Are you sure you want to delete this document?"
                      }
                      onConfirm={() =>  handleDeleteDocument(doc_id)}
                      onClose={() => {
                        // setOtherincomeNumber(0);
                        setConfirmDelete(false);
                      }}
                    />
            </>
    )
}
